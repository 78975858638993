import React, { useRef, useState, useEffect, useContext } from 'react';
import './style.scss'
import Icons from '../Icons';
import NoResult from "../../assets/img/no-result.png"
import { Tooltip } from 'react-tooltip';
import { AppContext } from '../../context/appContext'
import { json, useNavigate } from 'react-router-dom';
import { getApiCall, getApiCallNew, postApiCall, postApiCallNew, putApiCall, putApiCallNew } from '../../service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderNew from '../LoaderNew';
import FilterSidebar from '../FilterSidebar';
import WarningInfo from '../WarningInfo';
import Status from '../Status';
import HistoryModal from '../HistoryModal';
import ActionDropdown from '../ActionDropdown';

const OffersHubTable = (props) => {
  const { setOfferApiCall, searchedValues,setSearchedValues, searchTerms, setSearchTerms, dropdowns, setDropdowns,optionValues, setOptionValues,selectedValues,setSelectedValues, offset, total, limit, setOffset, setSort, setSortOrder, sort, sortOrder,offerListApi, offerSearchList, merchantSearch, setMerchantSearch, searched, deleteSearch  } = props;
  const navigate = useNavigate();
  const [fopen, setFopen] = useState(false)
  const [currentPage,setCurrentPage] = useState(1);
  const [lastList, setLastList] = useState({});
  const [activeRow, setActiveRow] = useState(null);
  const { state, dispatch } = useContext(AppContext);
  const [totalSelected, setTotalSelected] = useState([])
  const [loading,setLoading] = useState(false)
  const [showWarnig,setShowWarning] = useState(0)
  // const [openDropDownID,setOpenDropDownID] = useState(null)
  const [rejectReasonPopup,setRejectReasonPopup] = useState(false)
  const innerDivRef = useRef(null);
  // const actionRef = useRef(null);
  const [rejectedId,setRejectedId] = useState(null)
  const [Reasons,setReasons] = useState('')
  const wrapperRef = useRef(null)
  let tableHeader = props?.showFilters ?
    [{label:'Offer ID', value:"offerId"}, 
    {label:'Offer Title', value:"offerTitle"}, 
    {label:'Status', value:"offerStatus"}, 
    {label:'Offer Type', value:"offerType"}, 
    {label:'Merchant', value:"merchantName"},
    {label:'Offer Source', value:"offerSource"},
    {label:'Start Date', value:"validFrom"}, 
    {label:'End Date', value:"validTo"}, 
    {label:'Channels', value:"channel"}, 
    {label:'Action',value:"action"}] :
    [{label:'Offer ID', value:"offerId"}, 
    {label:'Offer Title', value:"offerTitle"}, 
    {label:'Status', value:"offerStatus"}, 
    {label:'Offer Type', value:"offerType"}, 
    {label:'Start Date', value:"validFrom"}, 
    {label:'End Date', value:"validTo"}, 
    {label:'Channels', value:"channel"}]

    // State to store selected options for each page

    const [selectedOptions, setSelectedOptions] = useState([{}])
    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)
    const [startendDate,setStartendDate] = useState(null)
    const [endendDate,setEndendDate] = useState(null)
    const isViewer = localStorage.getItem("role") === "Viewer"
    const { offerFilters } = state;
    const [historyDet,setHistoryDet] = useState([])
    const [sDateError, setSDateError] = useState(false);
    const [eDateError, setEDateError] = useState(false);
    const [showError,setShowError] = useState(false)

    useEffect(()=> {
      localStorage.removeItem("wonderList")
    },[])

  useEffect(() => {
    if (!rejectReasonPopup) {
      setShowError(false)
      setReasons('')
    }
  }, [rejectReasonPopup])

    useEffect(()=> {
      if(props.showFilters)
        props.data.map((offerDetails)=>{offerDetails.nextActions = offerDetails.nextActions.concat(",View History")})
    },[props.data])

    useEffect(()=>{
        localStorage.removeItem("wonderList")
        setShowWarning(0)
    },[sortOrder,merchantSearch])

    useEffect(() => {
      const storedSelectedOptions = JSON.parse(localStorage.getItem(`wonderList`));
      setSelectedOptions(storedSelectedOptions && storedSelectedOptions[parseInt((offset+limit)/limit)]? storedSelectedOptions[parseInt((offset+limit)/limit)] : [])
      const a = storedSelectedOptions ? Object.values(storedSelectedOptions).flat():[];
      setTotalSelected(a)
    },[offset,limit,localStorage.getItem("wonderList")])


// Function to compare if the selected offers have same set of actions

    // function deepCompareArraysIgnoringOrder(arrays) { 
    //   if (arrays.length <= 1) return true;
    //   function arraysEqual(arr1, arr2) {
    //     if (arr1.length !== arr2.length) return false;
    //     const sortedArr1 = [...arr1].sort();
    //     const sortedArr2 = [...arr2].sort();
        
    //     for (let i = 0; i < sortedArr1.length; i++) {
    //       if (sortedArr1[i] !== sortedArr2[i]) return false;
    //     }
        
    //     return true;
    //   }
      
    //   const firstArray = arrays[0];
    //   return arrays.every(arr => arraysEqual(arr, firstArray));
    // }
    
  

    const checkStatus = () => {
      const selectedList = JSON.parse(localStorage.getItem('wonderList'))
      const statuses = Object.values(selectedList).flat().map(item => item.status);
      //CHECKING IF ACTIONS FOR EACH OFFERS ARE SAME AFTER REMOVING EDIT AND VIEW
          // const actionsLists = Object.values(selectedList).flat().map(item => 
          //   item.nextActions.split(',').map(action => action.trim())
          // );
          // const newActions = actionsLists.map(actions =>actions.filter(action => action.trim() !== "Edit" && action.trim() !== "View"));      
      if(statuses.length <= 1){ 
        setShowWarning(0)
       return} 
      
      const temp1 = statuses.every(status => status === statuses[0]) && statuses.length>1 ;   
      let temp2;
      if(!temp1)
      {
        temp2 = statuses.every(status => status === "Requested to Pause" || status === "Requested to Resume" || status === "Pending for Approval" ) && statuses.length>1 ;   
      }       
      temp1 || temp2 ? setShowWarning(1) : setShowWarning(-1)
    }

    
    const handleClick = (event) => {
     
        setRejectReasonPopup(false); 
      
    };

    const handleReasonChange = (event) => {
      setReasons(event.target.value);
    };
    
    

    const handleCheckboxChange = (option) => {
      const currentIndex = selectedOptions?.find(item => item.id === option.id);      
      let newSelectedOptions = [...selectedOptions];
      if (currentIndex) {       
        newSelectedOptions = newSelectedOptions.filter(item => item.id !== option.id)
      } 
      else {
        newSelectedOptions = newSelectedOptions.concat(option);
      }

      const localList = JSON.parse(localStorage.getItem("wonderList"))
      let a=localList? localList:{}
      a[parseInt((offset+limit)/limit)] = newSelectedOptions;
      localStorage.setItem('wonderList',JSON.stringify(a))
  
      setSelectedOptions(newSelectedOptions);
      checkStatus();
    };
  
    const handleSelectAllChange = (event) => {
      if (event.target.checked) {
        const allOptions = props?.data?.map(({offerId,offerStatus,nextActions}) => ({"id":offerId,"status":offerStatus,"nextActions":nextActions}));
        const localList = JSON.parse(localStorage.getItem("wonderList"))
        let a=localList? localList:{}
        a[parseInt((offset+limit)/10)] = allOptions;
        localStorage.setItem('wonderList',JSON.stringify(a))
        setSelectedOptions(allOptions);
      } else {
        const localList = JSON.parse(localStorage.getItem("wonderList"))
        let a=localList? localList:{}
        a[parseInt((offset+limit)/10)] = [];
        localStorage.setItem('wonderList',JSON.stringify(a))
        setSelectedOptions([]);
      }
      checkStatus();
    };

    const handleNextPage = () => {
      if (offset + limit < total) {

        // const localList = JSON.parse(localStorage.getItem("wonderList"))
        // let a=localList? localList:{}
        // a[parseInt((offset+limit)/20)] = selectedOptions;
        // localStorage.setItem('wonderList',JSON.stringify(a))
        setOffset((offset + limit))
        
        setSelectedOptions([])
      }
    };
  
    const handlePreviousPage = () => {
      if (offset > 0) {
        // const localList = JSON.parse(localStorage.getItem("wonderList"))
        // let a=localList? localList:{}
        // a[parseInt((offset+limit)/20)] = selectedOptions;
        // localStorage.setItem('wonderList',JSON.stringify(a))
        setOffset(offset - limit)
        
        setSelectedOptions([])
      }
    };

const bulkSubmit = (action,SReasons,ReasonList) =>{
  let items = JSON.parse(localStorage.getItem("wonderList"));
  let offerids = Object.values(items)
    .flat()
    .map(item => ({ "offerId" : item.id }));
  if(action === "RejectIndividual"){
    let IdwithReason =Object.entries(ReasonList).map(([offerId, rejectionReason]) => ({
      offerId,
      rejectionReason
    }));   

    try {
      let actionType;
      let notification;
      let params;
      actionType = "OFFER_REJECT";
      notification = "Rejected"
      params = {
        "offerIds": IdwithReason,
        "actionType":actionType
      }
      
      setLoading(true)
      postApiCallNew("/offers/review",params).then(async(res)=> {
        if (res.status===200) {
          setShowWarning(0)
          setLoading(false)
          toast(
            <div className="custom-toast-container">
              <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
              <div className="custom-toast-text">
                <div>Offer Status Changed</div>
                <p>{selectedOptions.length} Offers status has been updated to "{notification}"</p>
              </div>
            </div>,
            {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              className:'custom-toast'
            }
          );
          localStorage.removeItem("wonderList")
          setSelectedOptions([{}])
          // offerSearchList();
          fetchFilterOptions();
        }
        else if (res?.status===500){
          setLoading(false)
          toast.error("Internal Server Error")
        }
        else if (res?.status===401){
          setLoading(false)
          localStorage.removeItem("token")
          navigate('/login')
          setTimeout(() => {
            toast.error("Session has expired. Please login again.")
          }, 1000);
        }
        else {
          let data = await(res.json())
          setLoading(false)
          toast.error(data?.error)
          toast.error(JSON.stringify(res?.error?.message))
          toast.error(JSON.stringify(res?.detail))
        }

      })
    }
    catch(e) {
      console.log(e)
    }
  }
  else if(action==="RejectCommon"){

    try {
      let actionType;
      let notification;
      let params;
      actionType = "OFFER_REJECT";
      notification = "Rejected"
      
      
      params = {
        "offerIds": offerids,
        "actionType":actionType,
        "commonRejectionReason": SReasons
      }
      
      setLoading(true)
      postApiCallNew("/offers/review",params).then(async(res)=> {
        if (res.status===200) {
          setShowWarning(0)
          setLoading(false)
          toast(
            <div className="custom-toast-container">
              <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
              <div className="custom-toast-text">
                <div>Offer Status Changed</div>
                <p>{selectedOptions.length} Offers status has been updated to "{notification}"</p>
              </div>
            </div>,
            {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              className:'custom-toast'
            }
          );
          localStorage.removeItem("wonderList")
          setSelectedOptions([{}])
          // offerSearchList();
          fetchFilterOptions();
        }
        else if (res?.status===500){
          setLoading(false)
          toast.error("Internal Server Error")
        }
        else if (res?.status===401){
          setLoading(false)
          localStorage.removeItem("token")
          navigate('/login')
          setTimeout(() => {
            toast.error("Session has expired. Please login again.")
          }, 1000);
        }
        else {
          let data = await(res.json())
          setLoading(false)
          toast.error(data?.error)
          toast.error(JSON.stringify(res?.error?.message))
          toast.error(JSON.stringify(res?.detail))
        }

      })
    }
    catch(e) {
      console.log(e)
    }
  }
  else if(action==="Approve")
    {
      try {
        let actionType;
        let notification;
        let params;
        actionType = "OFFER_APPROVE";
        notification = "Scheduled"
        params = {
          "offerIds": offerids,
          "actionType":actionType
        }
        
        setLoading(true)
        postApiCallNew("/offers/review",params).then(async(res)=> {
          if (res.status===200) {
            setShowWarning(0)
            setLoading(false)
            toast(
              <div className="custom-toast-container">
                <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
                <div className="custom-toast-text">
                  <div>Offer Status Changed</div>
                  <p>{selectedOptions.length} Offers status has been updated to "{notification}"</p>
                </div>
              </div>,
              {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                className:'custom-toast'
              }
            );
            localStorage.removeItem("wonderList")
            setSelectedOptions([{}])
            // offerSearchList();
            fetchFilterOptions();
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            let data = await(res.json())
            setLoading(false)
            toast.error(data?.error)
            toast.error(JSON.stringify(res?.error?.message))
            toast.error(JSON.stringify(res?.detail))
          }

        })
      }
      catch(e) {
        console.log(e)
      }
    }
  else if(action==="Edit"||action==="View")
    {}  
  else
    {
      try {
        let actionType;
        let notification;
        let params;
        actionType = action;
        notification = action==="OFFER_PAUSE" ? "Requested to pause" : action==="OFFER_RESUME" ? "Requested to resume" : action==="OFFER_WITHDRAW" ? "Draft" : "Pending for Approval"
        let offerids = Object.values(JSON.parse(localStorage.getItem("wonderList"))).flat().map(item=>item.id)
        params = {
          "offerIds": offerids,
          "actionType":actionType
        }
        setLoading(true)
        postApiCallNew("/offers/status",params).then(async(res)=> {
          if (res.status===200) {
            setShowWarning(0)
            setLoading(false)
            toast(
              <div className="custom-toast-container">
                <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
                <div className="custom-toast-text">
                  <div>Offer Status Changed</div>
                  <p>{selectedOptions.length} Offers status has been updated to "{notification}"</p>
                </div>
              </div>,
              {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                className:'custom-toast'
              }
            );
            localStorage.removeItem("wonderList")
            setSelectedOptions([{}])
            // offerSearchList();
            fetchFilterOptions();
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            let data = await(res.json())
            setLoading(false)
            toast.error(data?.error)
            toast.error(JSON.stringify(res?.error))
            toast.error(JSON.stringify(res?.error?.message))
            toast.error(JSON.stringify(res?.detail))
          }

        })
      }
      catch(e) {
        console.log(e)
      }
    }

}

    const handleActionCall = async (action,id) => {
      action = action.trim()
      if(action === "Edit" || action==="View" || action === "Duplicate"){
        editOffer(id,[],action)
      }
      else if(action==="Approve"){
        singleOfferSubmit(id,action)
      }
      else if(action === "Reject"){
        setRejectedId(id)
        setRejectReasonPopup(true)
      }
      else if(action === "View History"){
        document.documentElement.style.overflow = 'hidden';
        setLoading(true) 
        let params = {
          "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
          "filters": {
          }
        }
        postApiCallNew(`/offers/history/${id}`,params).then(async(res)=> {
          if (res.status===200) {
            let response = await(res.json())
            response.offerHistoryList = response.offerHistoryList.map((item, index) => ({
              id: index + 1,
              ...item
          }));
            setHistoryDet(response)
            setLoading(false)
            
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            setLoading(false)
            toast.error(res?.error)
          }
        })
        }
        else{
          singleOfferDec(id,action)
        }
      }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setActiveRow(null)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);


  const editOffer = (id,nextActions,clickedAction) => {    
    nextActions = nextActions?.map(item => item.trim());
    let action = "Edit";
    if(clickedAction === "Duplicate") action = "Duplicate"
    else if(clickedAction === "View") action = "View"
    else 
      if(nextActions?.length > 0 && !nextActions?.includes("Edit"))
        action = "View"

    navigate('/offers/add-offers',{state: { id, searched, action }})
    dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Add Offers' });
    dispatch({ type: "SET_UPLOADTAB", payload: 'Single Upload' });
  }

  const approval = (dec) => {
    // const localList = JSON.parse(localStorage.getItem("wonderList"))
    // let a=localList? localList:{}
    // a[parseInt((offset+limit)/20)] = selectedOptions;

    // localStorage.setItem('wonderList',JSON.stringify(a))

    try {
      let params ={
        "id": Object.values(totalSelected).flat(),
        "action":dec,
        "email":localStorage.getItem("email")
      }
      setLoading(true)
      putApiCallNew("/offers/approval",params).then((res)=> {
        
        if (res.status===200) {
          setLoading(false)
          toast.success(res.status)
          localStorage.removeItem("wonderList")
          offerSearchList();
        }
        else if (res?.status===500){
          setLoading(false)
          toast.error("Internal Server Error")
        }
        else if (res?.status===401){
          setLoading(false)
          localStorage.removeItem("token")
          navigate('/login')
          setTimeout(() => {
            toast.error("Session has expired. Please login again.")
          }, 1000);
        }
        else {
          setLoading(false)
          toast.error(JSON.stringify(res?.error?.message))
          toast.error(JSON.stringify(res?.detail))
        }
      })
    }
    catch(e) {
      console.log(e)
    }
    }
    
  const offerDec = (dec) => {
  
      try {
        if(totalSelected.length!==0) {
          let params ={
            "offerIds": Object.values(totalSelected).flat(),
            "actionType":dec==="pause" && "OFFER_PAUSE",
          }
          setLoading(true)
          console.log(params)
          postApiCallNew("/offers/status",params).then((res)=> {
            
            if (res.status===200) {
              setLoading(false)
              toast.success("Offer pause has been initiated. The offer will be paused in 15 mins")
              localStorage.removeItem("wonderList")
              offerSearchList();
            }
            else if (res?.status===500){
              setLoading(false)
              toast.error("Internal Server Error")
            }
            else if (res?.status===401){
              setLoading(false)
              localStorage.removeItem("token")
              navigate('/login')
              setTimeout(() => {
                toast.error("Session has expired. Please login again.")
              }, 1000);
            }
            else {
              setLoading(false)
              toast.error(JSON.stringify(res?.error?.message))
              toast.error(JSON.stringify(res?.detail))
            }
          })
        }
      }
      catch(e) {
        console.log(e)
      }
    }
    const singleOfferDec = (id,dec) => {  
      try {
        let actionType;
        let notification;
        switch (dec) {
          case "Pause":
            actionType = "OFFER_PAUSE";
            notification = "paused"
            break;
          case "Resume":
            actionType = "OFFER_RESUME";
            notification = "resumed"
            break;
          case "Submit for Approval":
            actionType = "OFFER_SUBMIT";
            notification = "submitted for approval"
            break;
          case "Withdraw":
            actionType = "OFFER_WITHDRAW";
            notification = "withdrawn"
            break;
        }
        let params ={
          "offerIds": [id],
          "actionType":actionType
        }
        setLoading(true)
        postApiCallNew("/offers/status",params).then(async(res)=> {
          if (res.status===200) {
            setLoading(false)
            toast(
              <div className="custom-toast-container">
                <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
                <div className="custom-toast-text">
                  <div>Offer Status Changed</div>
                  <p>offer ID “{id}” status has been updated to {notification === 'resumed' ? 'Requested to Resume' : notification === 'paused' ? 'Requested to Pause' : notification}</p>
                </div>
              </div>,
              {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                className:'custom-toast'
              }
            );
            localStorage.removeItem("wonderList")
            // offerSearchList();
            fetchFilterOptions();
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            let data = await(res.json())
            setLoading(false)
            toast.error(data?.error)
          }

        })
      }
      catch(e) {
        console.log(e)
      }
    }
    const singleOfferSubmit = (id,dec,rejreason) =>{
      try {
        let actionType;
        let notification;
        let params ;
        switch (dec) {
          case "Approve":
            actionType = "OFFER_APPROVE";
            notification = "approved"
            params = {
              "offerIds": [{"offerId":id}],
              "actionType":actionType
            }
            break;
          case "Reject":
            actionType = "OFFER_REJECT";
            notification = "rejected"
            params = {
              "offerIds": [{"offerId":id,"rejectionReason":rejreason}],
              "actionType":actionType
            }
            break;
        }
        
        setLoading(true)
        postApiCallNew("/offers/review",params).then(async(res)=> {
          if (res.status===200) {
            setLoading(false)
            toast(
              <div className="custom-toast-container">
                <div className="custom-toast-icon"><Icons name='checkboxcircled' height='28' width='28'/></div>
                <div className="custom-toast-text">
                  <div>Offer Status Changed</div>
                  <p>offer ID “{id}” has been {notification} and offer status has been updated</p>
                </div>
              </div>,
              {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                className:'custom-toast'
              }
            );
            localStorage.removeItem("wonderList")
            // offerSearchList();
            fetchFilterOptions();
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            let data = await res.json();
            setLoading(false)
            toast.error(data?.error)
          }

        })
      }
      catch(e) {
        console.log(e)
      }
    }
  
    const fetchFilterOptions = () => {
      try {
        getApiCallNew(`/offers/filters`).then(async (res) => {
      
          if (res?.status ===200) {
            setOptionValues({...optionValues,
              offerType:res?.data?.offerType?.slice(1,),
              status:res?.data?.status?.slice(1,),
              offerChannel:res?.data?.offerChannel?.slice(1,),
              offerSource:res?.data?.offerSource?.slice(1,),
            })
            let apiData = {
              offerType:res?.data?.offerType?.slice(1,),
              status:res?.data?.status?.slice(1,),
              offerChannel:res?.data?.offerChannel?.slice(1,),
              offerSource:res?.data?.offerSource?.slice(1,),
            }
            // Filter selectedValues to exclude invalid selections
            const updatedSelectedValues = { ...offerFilters };
        
            Object.keys(offerFilters).forEach((key) => {
              if(key!=="startDate" && key!=="endDate") {
              updatedSelectedValues[key] = offerFilters[key].filter((value) =>
                apiData[key]?.includes(value)
              );
              }
            });
            // Update state with new options and valid selected values
            console.log(apiData,updatedSelectedValues)
            setSelectedValues(updatedSelectedValues);
            dispatch({ type: "SET_OFFER_FILTERS", payload: { ...updatedSelectedValues } })
          }
          else if (res?.status===500){
            // setLoading(false)
            // toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            // setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            // setTimeout(() => {
            //   toast.error("Session has expired. Please login again.")
            // }, 1000);
          }
          else {
            // toast.error(res?.error)
            // setLoading(false)
          }
        }).catch(e=>{
          console.log(e)
        });
      
      } catch (error) {
        console.error("Error fetching filter options:", error);
      }
    };

  const toggleFilter = () => {
    
    setFopen(!fopen)
    if (!fopen) {
      setOfferApiCall(true)
      // setSelectedValues(offerFilters)
      fetchFilterOptions();
      
      setStartDate(selectedValues["startDate"]?.length>0? selectedValues["startDate"][0]?.startDate:null)
      setEndDate(selectedValues["startDate"]?.length>0? selectedValues["startDate"][0]?.endDate:null)
      setStartendDate(selectedValues["endDate"]?.length>0? selectedValues["endDate"][0]?.startDate:null)
      setEndendDate(selectedValues["endDate"]?.length>0? selectedValues["endDate"][0]?.endDate:null)
      document.body.classList.add('no-scroll');
    } else {
      setOfferApiCall(false)
      setDropdowns({
        offerType: false,
        status: false,
        startDate: false,
        endDate: false,
        offerChannel: false,
        offerSource: false,
      });
      document.body.classList.remove('no-scroll');
    }
  }
  const options = ["Offer Type","Status","Start Date", "End Date","Offer Channel","Offer Source"]
  
  const [emptyList, setEmptyList] = useState(null)

  const handleSearchChange = (event, dropdownKey) => {
    setSearchTerms({ ...searchTerms, [dropdownKey]: event.target.value });
    setSearchedValues({ ...searchedValues, [dropdownKey]: []})
  };

  const applyFilter = () => {
    const cond1 = (selectedValues["startDate"][0]?.startDate && !selectedValues["startDate"][0]?.endDate) || (!selectedValues["startDate"][0]?.startDate && selectedValues["startDate"][0]?.endDate);
    const cond2 = (selectedValues["endDate"][0]?.startDate && !selectedValues["endDate"][0]?.endDate) || (!selectedValues["endDate"][0]?.startDate && selectedValues["endDate"][0]?.endDate);
    if (cond1) {
      toast.dismiss();
      toast.error("Please select valid date range")
      setSDateError(true)
    }
    if (cond2) {
      toast.dismiss();
      toast.error("Please select valid date range")
      setEDateError(true)
    }
    if (!cond1 && !cond2) {
      setSDateError(false)
      setEDateError(false)
      setOfferApiCall(false)
      dispatch({ type: "SET_OFFER_FILTERS", payload: { ...selectedValues } })
      toggleFilter()
      setOffset(0)
    }
  }

  const clearFilters = () => {
    setOffset(0)
    setSDateError(false)
    setEDateError(false)
    setOfferApiCall(false)
    setSelectedValues({ offerType: [],
      status: [],
      startDate:[],
      endDate:[],
      offerChannel: [],
      offerSource: [], })
    dispatch({
      type: "SET_OFFER_FILTERS", payload: {
        offerType: [],
        status: [],
        startDate: [],
        endDate: [],
        offerChannel: [],
        offerSource: [],
      }
    })
    // toggleFilter()
  }
  const clearSidebarFilters = () => {
    setSelectedValues({ offerType: [],
      status: [],
      startDate:[],
      endDate:[],
      offerChannel: [],
      offerSource: [], })
  }
  
  const renderSelectedFilters = () => {
    return Object.keys(offerFilters).map((key) => {
      const values = offerFilters[key];
      if (values?.length === 0) return null;
      
      const displayText = (values.length > 1 
        ? ` ${values[0]}, +${values.length - 1}`
        : values.join(', '));
      

        return (
          ((values[0]?.startDate && values[0]?.startDate !==null) && (values[0]?.endDate && values[0]?.endDate !==null))? 
          <div key={key} className={`filter-tag ${emptyList>3 && "tag-scroll"}`}>
            <span>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span> 
            <span className='is'>{" is"}</span>
            <span>{values[0].startDate}{" - "}{values[0].endDate}</span>
            <div onClick={() => {
              setSelectedValues({ ...selectedValues, [key]: [] })
              dispatch({type: "SET_OFFER_FILTERS", payload: {  ...selectedValues, [key]: [] }})
              // const countEmptyLists = Object.values(offerFilters).filter(list => list.length !== 0).length;
              // setEmptyList(countEmptyLists)
            }}>
              <Icons     
              name= "xcancel"
              width= "1.042vw"
              height= "1.852vh"
              fill= "#111827"
              />
            </div>
          </div>:
          ((values[0]?.startDate && values[0]?.startDate !==null) || (values[0]?.endDate && values[0]?.endDate !==null))?
          <div key={key} className={`filter-tag ${emptyList>3 && "tag-scroll"}`}>
            <span>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span> 
            <span className='is'>{" is"}</span>
            <span>{values[0]?.startDate !==null? values[0].startDate:values[0].endDate}</span>
            <div onClick={() => {
              setSelectedValues({ ...selectedValues, [key]: [] })
              dispatch({type: "SET_OFFER_FILTERS", payload: {  ...selectedValues, [key]: [] }})
              // const countEmptyLists = Object.values(offerFilters).filter(list => list.length !== 0).length;
              // setEmptyList(countEmptyLists)
            }}>
              <Icons     
              name= "xcancel"
              width= "1.042vw"
              height= "1.852vh"
              fill= "#111827"
              />
            </div>
          </div>:
          <div key={key} className={`filter-tag ${emptyList>3 && "tag-scroll"}`}>
            <span>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span> 
            <span className='is'>{values.length > 1? " are":" is"}</span>
            <span>{displayText}</span>
            <div onClick={() => {
              setSelectedValues({ ...selectedValues, [key]: [] })
              dispatch({type: "SET_OFFER_FILTERS", payload: {  ...selectedValues, [key]: [] }})
              // const countEmptyLists = Object.values(offerFilters).filter(list => list.length !== 0).length;
              // setEmptyList(countEmptyLists)
            }}>
              <Icons     
              name= "xcancel"
              width= "1.042vw"
              height= "1.852vh"
              fill= "#111827"
              />
            </div>
          </div>
        
        );
    });
  };

  const countFilledLists = offerFilters ? Object.values(offerFilters).filter(list => list.length !== 0).length : [];
  const threshold = (merchantSearch && totalSelected.length >=1)? 2:(merchantSearch || totalSelected.length >=1)? 3:4;
  return (
    <>
    {
      loading && <LoaderNew/>
    }
    {fopen && <>
      <div className="backdrop" onClick={toggleFilter}></div></>}
      { props.showFilters && <FilterSidebar 
      options={options} 
      dropdowns={dropdowns}
      setDropdowns={setDropdowns}
      optionValues={optionValues}
      setOptionValues={setOptionValues}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      isFilterOpen={fopen} 
      toggleFilter={toggleFilter}
      applyFilter={applyFilter}
      clearFilters={clearFilters}
      clearSidebarFilters={clearSidebarFilters}
      searchTerms={searchTerms}
      searchedValues={searchedValues}
      setSearchedValues={setSearchedValues}
      setSearchTerms={setSearchTerms}
      handleSearchChange={handleSearchChange}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      startendDate={startendDate}
      setStartendDate={setStartendDate}
      endendDate={endendDate}
      setEndendDate={setEndendDate}
      sDateError={sDateError}
      eDateError={eDateError}
      />
      }
      {/* {
        props?.showFilters &&
        <div className='filter-div'>
          <div>
            <Icons
              name="search"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Search</span>
          </div>
          <div>
            <Icons
              name="sort"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Sort</span>
          </div>
          <div>
            <Icons
              name="filter"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Filters</span>
          </div>
          <div className='pagination'>
            1 - 100 of 4,142
            <Icons
              name="left-arrow"
              width="7"
              height="13"
              fill="rgba(0, 0, 0, 1)"
            />
            <Icons
              name="right-arrow"
              width="7"
              height="13"
              fill="rgba(0, 0, 0, 1)"
            />
          </div>
        </div>
      } */}
      {
        props?.showFilters &&
      <div className='filter-div-offer' style={{ marginTop: 35, marginBottom: 30 }} >
        <div className='filter-search'>
        {merchantSearch && 
        <><div className='search-heading'>
          <span onClick={deleteSearch} style={{paddingTop:"3px"}}>
          <Icons
              name="nav-arrow-left"
              width="32"
              height="32"
              fill="#004BEC"
          />
          </span>
          Showing Results for <span className='search-name'>"{searched?.label}"</span>
        </div>
        
        </>
        }
        {merchantSearch && (totalSelected.length >=1 && <div className='sep'>|</div>)}
        {totalSelected.length >=1 && <div className='selected-offers'>{totalSelected.length} Offers <span>selected out of {total} </span> </div>}        
        {(merchantSearch||totalSelected.length >=1) &&(countFilledLists>0 && <div className='sep'>|</div>)}
        <div className={`selected-filters ${countFilledLists>threshold && "filter-scroll"}`}>
        {renderSelectedFilters()}
        </div>
        {(offerFilters.offerType.length !==0 ||
                offerFilters.status.length !==0 ||
                offerFilters.startDate.length !==0 ||
                offerFilters.endDate.length !==0 ||
                offerFilters.offerChannel.length !==0 ||
                offerFilters.offerSource.length !==0) && 
          <div style={{marginLeft:"8px",alignItems:"end",minWidth:"fit-content"}} onClick={clearFilters}>
            <span style={{color:"#EF4444",fontSize:14,marginLeft:"0",fontWeight:500}}>clear all</span>
            <div style={{marginLeft:"0.208vw"}}>
              <Icons     
              name= "xcancel"
              width= "18px"
              height= "18px"
              fill= "#EF4444"
              />
            </div>
          </div>
        }
        </div>
        
        <div className='flex'>
          <div onClick={toggleFilter}>
            <div style={{position:"relative"}}>
              <div>
                <Icons
                name="filter"
                width="24"
                height="24"
                fill="#1F2937"
              />
              </div>
              {(offerFilters.offerType.length !==0 ||
                offerFilters.status.length !==0 ||
                offerFilters.startDate.length !==0 ||
                offerFilters.endDate.length !==0 ||
                offerFilters.offerChannel.length !==0 ||
                offerFilters.offerSource.length !==0) &&
              <div style={{position:"absolute",top:"-4px",right:"-3px"}}>
                <Icons
                  name="red"
                  width="14"
                  height="14"
                  fill="#1F2937"
              />
              </div>}

            </div>
            
            <span style={{color:"#1F2937"}}>Filters</span>
          </div>
          <div className='sep1'>|</div>
          <div className='pagination'>
          {props.data?.length===0? 0:offset + 1} - {offset + limit > total ? total : offset + limit} of {total}
          <span onClick={() => { handlePreviousPage()}} >
            <Icons
              name="left-arrow"
              width="7"
              height="13"
              fill={offset === 0 ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            /></span>
          <span onClick={() => { handleNextPage()}} >
            <Icons
              name="right-arrow"
              width="7"
              height="13"
              fill={offset + limit >= total ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            />
          </span>
        </div>
        
        </div>
        
      </div>
      }
      <div className='offers-hub-table'>
        <table style={{position:"relative"}}>
        <thead>
           {!isViewer && <td 
            >
              { props?.showFilters &&
              <label className="container">
                <input
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectedOptions?.length >= 1}
                />
                <span className={selectedOptions?.length === props.data?.length && props.data?.length !== 0 ? 'checkmark1' : 'checkmark2'}></span>
              </label>
              }
            </td>}
            {tableHeader && tableHeader.map(
              ({ label, value }) => {
                return (<td>
                  {!isViewer ?
                  <div
                    style={{cursor:value==="action"&&"default"}}
                    onClick={
                      () => {
                        setShowWarning(0)
                        if(props?.showFilters && value!=="action")
                          {
                        if (sortOrder === "asc" && sort === value) {
                          setSortOrder("desc")
                        }
                        else if (sortOrder === "desc" && sort === value) {
                          setSortOrder("")
                          setSort("")
                        }
                        else {
                          setSortOrder("asc")
                          setSort(value)
                        }
                      }
                    }
                    }
                  >
                    {label}
                    {
                      value !== 'action' && props?.showFilters &&
                      <span className='sort' >
                        <div><Icons name="arrow-up" width={6} height={8} fill={props?.sort === value && props.sortOrder === "asc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                        <div><Icons name="arrow-down" width={6} height={8} fill={props?.sort === value && props.sortOrder === "desc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                      </span>
                    }
                  </div>:                  
                  <div
                  style={{cursor:value==="action"&&"default" ,paddingRight : value==="action" ? "12px":""}}
                  onClick={
                    () => {
                      if(props?.showFilters && value!=="action")
                        {
                      if (sortOrder === "asc" && sort === value) {
                        setSortOrder("desc")
                      }
                      else if (sortOrder === "desc" && sort === value) {
                        setSortOrder("")
                        setSort("")
                      }
                      else {
                        setSortOrder("asc")
                        setSort(value)
                      }
                    }
                  }
                  }
                >
                  {label}
                  {
                    value !== 'action' && props?.showFilters &&
                    <span className='sort' >
                      <div><Icons name="arrow-up" width={6} height={8} fill={props?.sort === value && props.sortOrder === "asc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                      <div><Icons name="arrow-down" width={6} height={8} fill={props?.sort === value && props.sortOrder === "desc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                    </span>
                  }
                </div>}
                </td>)
              }
            )}
          </thead>
          {props.data?.length===0 ? 
            <div className='no-result'>
              <div className='no-img'><img src={NoResult} alt="no-result" height={180} width={155}/></div>
              <div className='nor-text'>Sorry, no results found :(</div>
              <div className='nor-text'>Try Searching again</div>
            </div>
            :
          <tbody>
            {props.data?.map((offerDetails,index) => {              
              return (<tr   
                onClick={()=>{
                  if(offerDetails?.nextActions) {
                    editOffer(offerDetails.offerId,offerDetails?.nextActions?.split(','))
                  }
                
              }} 
              // style={{cursor:'pointer'}}
              >
                {!isViewer && <td onClick={(event) => {
                      event.stopPropagation();
                    }}
                    style={{width:"3%"}}>
                  { props?.showFilters &&
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange({"id":offerDetails.offerId,"status":offerDetails.offerStatus,"nextActions":offerDetails.nextActions})}
                    checked={selectedOptions?.find(item=>item.id===offerDetails.offerId)}
                  />
                  <span className="checkmark1"></span>
                </label>
                }
                  
                </td>}
                <td style={{width: isViewer? "7%" :"6%"}} >{offerDetails.offerId}</td>
                <td className='offer-table-desc'>
                  {offerDetails?.offerTitle ? offerDetails?.offerTitle:"-" }
                  {/* <div
                  data-tooltip-id="offer-description"
                  data-tooltip-content={`${props?.showFilters? offerDetails?.offerDescription:offerDetails?.offerDetails}`}
                  >
                  {props?.showFilters? 
                  (offerDetails.offerDescription?.length > 40 ? offerDetails.offerDescription?.substring(0, 40) + '...' : offerDetails.offerDescription)
                  :(offerDetails.offerDetails?.length > 40 ? offerDetails.offerDetails?.substring(0, 40) + '...' : offerDetails.offerDetails)}</div> */}
                  {/* <div 
                  data-tooltip-id="offer-description"
                  data-tooltip-html={`${props?.showFilters? offerDetails?.offerDescription:offerDetails?.offerDetails}`}
                  dangerouslySetInnerHTML={{ __html: props?.showFilters? 
                  (offerDetails.offerDescription?.length > 40 ? offerDetails.offerDescription?.substring(0, 40) + '...' : offerDetails.offerDescription)
                  :(offerDetails.offerDetails?.length > 40 ? offerDetails.offerDetails?.substring(0, 40) + '...' : offerDetails.offerDetails) }} /> */}
                  {/* <Tooltip 
                  id="offer-description"
                  style={{maxWidth:"300px"}} /> */}
                </td>
                <td style={{width:"15%"}}>
                  {(() => {
                    switch(offerDetails.offerStatus.trim()) {
                      case "Live":
                        return <Status text="Live" textColor="#14532D" bgColor="#F0FDF4" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason} />;
                      case "Rejected":
                        return <Status text="Rejected" textColor="#DC2626" bgColor="#FEF2F2" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason} warningMsgDefaultText="Rejected Reason for Offer Publish" />;
                      case "Draft":
                        return <Status text="Draft" textColor="#374151" bgColor="#F3F4F6" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Requested to Pause":
                        return <Status text="Requested to Pause" textColor="#B010B6" bgColor="#FFE8FB" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Requested to Resume":
                        return <Status text="Requested to Resume" textColor="#B010B6" bgColor="#FFE8FB" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Paused":
                        return <Status text="Paused" textColor="#CFAF0F" bgColor="#FEFFE8" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Expired":
                        return <Status text="Expired" textColor="#CC5120" bgColor="#FFF3E8" index={index} icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Scheduled":
                        return <Status text="Scheduled" textColor="#002D8E" bgColor="#E6EDFD" index={index}  icon="warningcircled" warningMsg={offerDetails.rejectionReason}/>;
                      case "Resume In Progress":
                        return <Status text="Resume In Progress" textColor="#001E5E" bgColor="#C3E7FE" icon="warningcircled" index={index} warningMsg={offerDetails.rejectionReason}/>;
                      case "Pause In Progress":
                        return <Status text="Pause In Progress" textColor="#001E5E" bgColor="#C3E7FE" icon="warningcircled" index={index} warningMsg={offerDetails.rejectionReason}/>;
                      case "Pending for Approval":
                        return <Status text="Pending for Approval" textColor="#D97706" bgColor="#FFFBEB" icon="warningcircled" index={index} warningMsg={offerDetails.rejectionReason}/>;
                      default:
                        return <Status text={offerDetails.offerStatus} textColor="#CFAF0F" bgColor="#FFF3E8" icon="warningcircled" index={index} warningMsg={offerDetails.rejectionReason}/>;
                    }
                  })()}
                </td>
                <td style={{width: isViewer? "9%" :"8%"}}>{offerDetails.offerType?.charAt(0).toUpperCase() + offerDetails.offerType?.slice(1).toLowerCase()}</td>
                {props?.showFilters &&
                  <>
                    <td className='offer-table-merchant'>{offerDetails.merchantName}</td>
                    <td style={{width:"8%"}}>{offerDetails.offerSource}</td>
                  </>
                }
                <td style={{width: isViewer? "10%" :"8%"}}>{offerDetails.validFrom?.split(' ')[0]}</td>
                <td style={{width: isViewer? "10%" :"8%"}}>{offerDetails.validTo?.split(' ')[0]}</td>
                <td style={{width: isViewer? "9%" :"7%"}}>{offerDetails.channel?.charAt(0).toUpperCase() + offerDetails.channel?.slice(1).toLowerCase()}</td>
                {!props?.showFilters ? <></> :
                  <td style={{position:'relative',width:'5%'}}>
                  <ActionDropdown
                    key={offerDetails?.offerId}
                    offerDetails={offerDetails}
                    index={index}
                    handleActionCall={handleActionCall}
                  />
                  </td>
                    // <td className='cursor'>
                    //   <div className='more-actions' onClick={(e) => { e.stopPropagation(); setActiveRow(offerDetails.id) }}>...</div>
                    //   {activeRow === offerDetails.id && <div className='more-actions-menu' ref={wrapperRef}>
                    //     <div onClick={() => { setActiveRow(null) }}>Edit</div>
                    //     <div onClick={() => { setActiveRow(null) }}>Preview</div>
                    //     <div onClick={() => { setActiveRow(null) }}>Delete</div>
                    //   </div>}
                    // </td>
                    }
              </tr>)
            })}
          </tbody>
          }
        </table>
      </div>
      {Object.keys(historyDet).length > 0 && <HistoryModal setHistoryDet={setHistoryDet} setLoading={setLoading} OfferId={historyDet.offerId} OfferStatus={historyDet.offerStatus} MerchantName={historyDet.merchantName} OfferTitle={historyDet.offerTitle} OfferDetails ={historyDet.offerHistoryList}/>}
      {showWarnig !== 0 && totalSelected.length >=2 && <WarningInfo  bulkSubmit={bulkSubmit} setShowWarning={setShowWarning} totalOffers={totalSelected.length} type={showWarnig} setSelectedOptions={setSelectedOptions}></WarningInfo>}  
      {rejectReasonPopup && (
       <div   className="actionChangeModal" >
         <div ref={innerDivRef} className="innerDiv" style={{width:"390px"}}>
          <div> <span className="closeIcon" onClick={handleClick}>
            <Icons fill="#6B7280" name='xcancel' height='18' width='18'/></span>
          </div>
           <div className="ReasonsTitle">Rejected Reason for Offer Publish </div>
           <div className="EntRea"> Mention the reason for rejecting offer ID : {rejectedId}</div> 
           <div className="SingleReasons"> 
              <div>Reason*</div>
              <textarea className="textArea" value={Reasons} onChange={handleReasonChange} placeholder="Enter your Reasons" />
              {
                showError &&
                <div className='show-error' >Rejection reason exceeds the maximum limit of 255 characters.</div>
              }
              <button className={`Submit ${Reasons.trim().length>=1 ? 'active' :''}`} onClick={()=>{
                if(Reasons.trim().length>=1 ){
                  if(Reasons.length>255)
                  {
                    setShowError(true);
                  }
                  else
                  {
                    singleOfferSubmit(rejectedId,"Reject",Reasons);setRejectReasonPopup(false);setRejectedId(null);setReasons('')
                  }
                }
                }}> Submit </button>
           </div> 
       </div> 
   </div>
      )}
      <ToastContainer
      // className="toaster-top toast-error-container"
      autoClose={6000}
      position='top-right'
    />
    </>
  )
}

export default OffersHubTable