import React, { useRef, useState, useEffect } from 'react';
import Icons from '../Icons';

function ActionDropdown({ offerDetails, index, handleActionCall }) {
  const [openDropDownID,setOpenDropDownID] = useState(null)
  const actionRef = useRef(null);

  useEffect(() => {
      function handleClickOutside(e) {
        if (openDropDownID && actionRef.current && !actionRef.current.contains(e.target)) {
          setOpenDropDownID(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [openDropDownID]);

    const handleDropdownToggle = (offerid, e) => {
        e.stopPropagation(); 
        setOpenDropDownID(openDropDownID === offerid ? null : offerid);
      };

  return (
    <div className='actionButton' ref={actionRef} onClick={(e) => handleDropdownToggle(offerDetails.offerId, e)}>
                    <span><Icons name="opt-dots" hieght="18px" width="18px" ></Icons></span>
                    
                    {/* {offerDetails.offerId === openDropDownID &&  */}
                      <div 
                        className={`actions-dropdownlist ${offerDetails.offerId === openDropDownID? "open-action":"close-action"} ${index === 9 || index===8 ? "upwards" : "downwards"} `} 
                        
                      >
                        {offerDetails?.nextActions.split(',').map((element) => (
                          <button className='action-new' onClick={(e) => {
                            e.stopPropagation();
                            handleActionCall(element, offerDetails.offerId);
                            setOpenDropDownID(null);
                            
                          }}>
                            {element}
                          </button>
                        ))}
                      </div>
                     {/* }  */}
                    </div>
  );
}

export default ActionDropdown;
